/* spec */
* {
    margin: 0;
    padding: 0;
}

:root {
    --background: #242538;
    --dark-blue: #343552;
    --text-blue: #3F4281;
    --light-blue: #8588C8;

    --gray: #808080;
    --light-gray: #b0b0b0;
    --yellow: #FFFF00;
    --yellow-background: #FFE500;
    --gold: #FFB506;
    --white: #fff;
    --gold-gradient: linear-gradient(244.59deg, #FFE100 -0.06%, #FFB700 48.63%, #FFE100 99.95%);
}

html {
    scroll-behavior: smooth;
}

body {
    /* background-color: var(--background); */
    background: radial-gradient(66.3% 66.3% at 50% 58.22%, #191C2A 0%, #231F3D 100%);
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
}

p {
    color: var(--white);
    line-height: 1.5em;
}

h1 {
    font-weight: 900;
    color: var(--white);
    font-size: 35px;
    line-height: 1.2em;
    letter-spacing: 3px;
    text-transform: uppercase;
}

h1 span {
    color: var(--yellow);
}

h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: var(--white);
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

input {
    border: 1px solid var(--light-gray);
    /* color: ; */
    border-radius: 5px;
    padding: 18px 20px;
    height: 42px;
    box-sizing: border-box;
}

textarea:focus,
input:focus {
    /* color: var(--gold); */
    outline: none;
    border: 1px solid var(--gold) !important;
}

textarea:invalid,
input:invalid {
    border: 1px solid #D91B1B !important;
    color: #D91B1B;
}

button {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 14px 12px;
    box-sizing: border-box;
    color: var(--background);
    /* font-weight: 700; */
    background-color: var(--yellow-background);
    transition: 0.3s;
}


button:hover {
    box-shadow: 0px 0px 12px 0px rgba(246, 163, 0, 0.50);
    background: #FFB800;
}

button:active {
    background: #F6A300;
}

section {
    padding-bottom: 40px;
}

.global_wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container {
    width: 1170px;
    margin: 0 auto;
    position: relative;
}

.section__heading {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.section__heading .heading__icon {
    height: 30px;
    width: 30px;
    background: var(--gold-gradient);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.section__heading .heading__icon img {
    height: 20px;
    width: 20px;
}

/* components  */

.drop__list {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
}

.drop__list.active {
    max-height: 100px;
}


.modal__wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #000000aa;
    z-index: 100;
}

.modal--success .modal__content{
    width: 355px;
}

.modal__order .modal__content{
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.modal--success .modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 355px;
    text-align: center;
}

.modal--success button {
    width: 100%;
}

.modal--success ul {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 25px;
}

.modal__content {
    background-color: var(--white);
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
}

.modal__content h2 {
    color: var(--background);
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 15px;
}

.modal__content p {
    color: var(--background);
    margin-bottom: 25px;
}

.modal__enroll {
    width: 395px;
    height: 350px;
}

.modal__enroll h2 {
    color: var(--background);
    font-size: 28px;
    font-weight: 900;
    line-height: 38px;
    margin-bottom: 20px;
}

.modal__enroll form {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 170px);
    gap: 15px;
}

.modal__enroll form input:last-of-type {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
}

.modal__enroll form button {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;
}

/* header */
.header {
    position: relative;
    display: flex;
    padding-top: 60px;
    padding-bottom: 100px;
}

.header__text {
    flex-basis: 60%;
    position: relative;
    z-index: 2;
}

.header__text h1 {
    margin-bottom: 10px;
}

.header__text p {
    margin-bottom: 30px;
    font-size: 20px;
}

.header__list {
    margin-bottom: 50px;
}

.header__item {
    display: inline-block;
    color: var(--white);
    vertical-align: middle;
    margin-right: 30px;
}

.header__item:last-child {
    margin-right: 0;
}

.header__item::before {
    content: url(./img/star.svg);
    vertical-align: middle;
    margin-right: 12px;
}

.header__text button {
    width: 270px;
}

.header__image {
    flex-basis: 40%;
}

.header__blop {
    position: absolute;
    right: -370px;
    top: -85px;
    z-index: -1;
}

.cross {
    position: absolute;
}

#cross1 {
    top: -50px;
    right: 450px;
    transform: scale(0.5);
}

#cross2 {
    top: 50px;
    right: 300px;
}

#cross3 {
    top: 80px;
    right: 150px;
    transform: scale(0.8);
}

#cross4 {
    top: 250px;
    right: 400px;
    transform: scale(0.6);
}

#cross5 {
    bottom: 200px;
    right: 250px;
    transform: rotate(45deg);
}

#cross6 {
    bottom: 100px;
    right: 450px;
    transform: rotate(45deg);
}

.blop-wrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 45px;
    top: 0;
    z-index: -1;
    /* background-color: #fff; */
}

.blop {
    position: absolute;
}

#blop2 {
    bottom: -115px;
    right: 350px;
}

.circle {
    position: absolute;
    top: 10px;
    right: 400px;
}

.circle-left {
    position: absolute;
    top: 250px;
    left: -150px;
}

.header__circle {
    position: absolute;
    top: -150px;
    right: -350px;
}

.header__circle-blop {
    position: absolute;
    right: -390px;
    bottom: 45px;
    width: 300px;
}

/* not found */

.not_found {
    text-align: center;
    /* height: 100vh; */
}

.not_found__banner {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.not_found__link {
    margin-top: 30px;
    display: inline-block;
    border-radius: 5px;
    width: 270px;
    height: 44px;
    background-color: var(--yellow-background);
    box-sizing: border-box;
    padding: 12px 0;
    color: var(--dark-blue);
    font-size: 14px;
    text-align: center;
    transition: 0.3s;
}

@media screen and (max-width: 425px) {
    .container {
        width: auto;
        padding: 0 10px;
    }

    nav {
        height: 55px;
    }

    .navbar {
        height: 40px;
        align-items: center;
        justify-content: space-between;
    }

    .logo img {
        height: 40px;
    }

    .navbar__social {
        display: none;
    }

    .navbar__numbers {
        font-size: 12px;
    }

    .navbar__nav {
        display: none;
    }

    .navbar.active .navbar__nav {
        display: block;
    }

    .navbar__toggler {
        background-color: transparent;
        display: block;
    }

    h1 {
        font-size: 20px;
        margin-bottom: 25px !important;
    }

    h2 {
        font-size: 22px;
    }

    .header {
        background: url(./img/header_bg.png) no-repeat;
        padding: 30px 15px;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .header::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        opacity: .5;
        background: linear-gradient(45deg, #4B4DB4 0%, #333390 100%);
    }

    .header__text p {
        display: none;
    }

    .header__list {
        margin-bottom: 20px;
    }

    .header__item {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .header__item:last-child {
        margin-bottom: 0;
    }

    .header__blop {
        display: none;
    }

    .header__img {
        display: none;
    }

    .ways .section__heading {
        margin-bottom: 0;
    }

    .ways__categories {
        flex-wrap: wrap;
    }

    .ways__categories button {
        font-size: 12px;
    }

    .banner {
        padding-bottom: 40px;
    }

    .banner__wrapper {
        height: auto;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .banner__wrapper .banner__icon {
        flex-shrink: 0;
    }

    .banner__wrapper p {
        font-size: 12px;
    }

    .plans__subheading {
        display: flex;
        flex-direction: column-reverse;
        gap: 15px;
        font-size: 12px;
    }

    .plans__cards {
        flex-direction: column;
        gap: 20px 0;
    }

    .plan__card {
        width: 100%;
    }

    .plan__card_description {
        font-size: 12px;
        font-weight: 700;
    }
    .plan__card_description::before {
        margin-right: 8px;
        vertical-align: middle;
        /* font-size: 16px; */
    }
    .for {
        display: none;
    }

    .how__card {
        position: static;
        width: 100%;
        height: 140px;
        margin-bottom: 15px;
    }

    .how__girl,
    .how__boy,
    .planet_s,
    .planet_b {
        display: none;
    }

    .how__card_pop {
        display: none;
    }

    .how__card::after {
        display: none;
    }

    .sertificate__wrapper {
        flex-direction: column-reverse;
    }

    .sertificate__img {
        /* display: none; */
    }

    .sertificate__form {
        width: 100%;
    }

    .sertificate .modal__enroll {
        width: 100%;
        height: auto;
    }

    .sertificate__form h2 {
        /* 22px */
        font-size: 20px;
    }

    .sertificate form {
        grid-template-columns: 100%;
        grid-template-rows: repeat(4, 1fr);
        grid-auto-flow: row;
    }

    .sertificate form input {
        width: 100%;
    }

    .modal__enroll form input:last-of-type {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .modal__enroll form button {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .faq {
        padding-bottom: 20px;
    }

    .faq .section__heading {
        margin-bottom: 20px;
    }

    .faq__card {
        border-radius: 8px;
        margin-bottom: 14px;
    }

    .faq__block,
    .faq__cards,
    .faq__card {
        width: auto;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 100%;
    }

    .faq__card_back {
        display: none;
    }

    .faq__card_description {
        font-size: 14px;
        color: #555555;
    }

    .faq__card_title {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 55px;
        font-size: 16px;
        line-height: 16px;
        min-height: 60px;
        height: auto;
        box-sizing: border-box;
    }

    .faq__card_title span {
        top: 50%;
        transform: translate(0, -50%);
        font-size: 30px;
    }

    .faq__card.active .faq__card_title span {
        transform: translate(0, -50%) rotate(45deg);
    }

    .faq__card.active .faq__card_body {
        max-height: 400px;
    }

    .faq__img {
        display: none;
    }

    .about__wrapper {
        border-radius: 8px;
        /* background: linear-gradient(45deg, #4D50C3 0%, #91267D 100%), #FFF; */
        flex-direction: column;
        padding: 10px;
    }

    .about__img img {
        width: 250px;
        margin-bottom: 20px;
    }

    .about__text blockquote {
        display: none;
    }

    .about__text p {
        text-align: justify;
    }

    .contacts__form {
        display: none;
    }

    .contacts__info {
        padding-left: 0;
    }
}



/* footer */