.program__cards {
    display: flex;
    flex-direction: column;
}

.program__card {
    position: relative;
    background-color: var(--white);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 25px;
}

.program__card_title {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    font-size: 18px;
    color: var(--background);
}

.program__card_label {
    /* padding-top: 5px; */
    font-size: 14px;
    height: 20px;
    width: 75px;
    line-height: 20px;
    flex-shrink: 0;
}

.program__card_number {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    font-size: 12px;
    background-color: var(--yellow-background);
    text-align: center;
}

.program__card_title strong {
    padding-right: 65px;
}

.program__card_title .program__card_btn {
    position: absolute;
    top: -17px;
    right: 15px;
    font-size: 40px;
    font-weight: 300;
    transition: 0.3s;
}

.program__card_body {
    overflow: hidden;
    max-height: 0;
    transition: 0.5s;
    display: flex;
    padding-left: 75px;
}

.program__card.active .program__card_body {
    max-height: 300px;
}

.program__card_paragraphs {
    padding-top: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
}


.program__card_paragraph {
    margin-bottom: 5px;
    color: #555555;
}

.program__card_paragraph::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
    background: #555555;
}


@media screen and (max-width: 425px) {
    .program .section__heading {
        margin-bottom: 20px;
    }

    .program__card {
        margin-bottom: 14px;
    }

    .program__card_title .program__card_btn {
        top: -10px;
        font-size: 30px;
    }

    .program__card.active .program__card_btn {
        transform: rotate(45deg);
    }

    .program__card_title {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .program__card_body {
        padding-left: 0;
        flex-direction: column;
    }

    .program__card.active .program__card_body {
        max-height: 1000px;
    }

    .program__card_paragraphs {
        padding-top: 0;
    }

    .program__card_paragraphs:first-child {
        padding-top: 20px;
    }

    .program__card_paragraph {
        color: var(--gray, #555);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Mobile/14 regular */
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
    }
}